import {Helmet} from "react-helmet-async";
import {useState} from "react";
// @mui
import {Box} from "@mui/material";
// sections and components
import {NewFoodDescription, NewFoodIngredients, NewFoodStepper} from "../sections/@dashboard/new-foods";

export default function NewFoodPage() {
    const initialState = {
        'name': null,
        'description': null,
        'nutritional_score': 'A',
        'calories': null,
        'serving': null,
        'serving_unit': 'ml',
        'price': null,
        'price_unit': '€',
        'ingredients': [],
    }
    const [state, setState] = useState(initialState)
    const [activeStep, setActiveStep] = useState(0);

    const handleChanges = (key: any) => {
        return (event: any) => {
            setState({...state, [key]: event.target.value})
        }
    }
    return (
        <>
            <Helmet>
                <title> New Food Product | CibusAI </title>
            </Helmet>
            <Box>
                <NewFoodStepper activeStep={activeStep} setActiveStep={setActiveStep} state={state}>
                    {activeStep === 0 ?
                        <NewFoodDescription state={state} handleChanges={handleChanges}/>
                        :
                            <NewFoodIngredients state={state} setState={setState}/>
                    }
                </NewFoodStepper>
            </Box>
        </>
    )
}