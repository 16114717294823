import React, {ChangeEvent, useState, useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {Box, Grid, InputAdornment, Stack, Typography, Tabs, Tab} from '@mui/material';
import {Search} from "@mui/icons-material";
import {
    SortIngredientCardList,
    FilterIngredientCardList,
    TypeIngredientCardList
} from "../sections/@dashboard/ingredients";
import {IngredientCard} from "../components/ingredient-card";
import {StyledSearch} from "../components/search";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {apiRoutes, ingredientTypesOptions} from "../config";
import Pagination from "@mui/material/Pagination";
import {SortOption, SortOptionCreateParams} from "../utils/filteringAndSorting";
import openAPIGeneratorMaterialLibraryInstance from "../openAPIGeneratorMaterialLibraryInstance";
import {useSearchParams} from "react-router-dom";
import {MaterialEntity} from "../api";
import {ClearFilterIngredientCardList} from "../sections/@dashboard/ingredients/FilterIngredientCardList";

// ---------------------------------------------------------------------------------------------------------------------

const sortInitialState: SortOption = {
    property: 'none',
    order: 'desc',
    label: 'None'
};

export const ingredientsPageQuery = (
    annotationsValue?: string[],
    searchQuery?: string,
    sort?: SortOption,
    page?: number,
    pageSize?: number,
    type?: string[]
) => ({
    queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, page, pageSize, searchQuery, sort, type, annotationsValue],
    queryFn: () => {
        return openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesList(annotationsValue, sort ? SortOptionCreateParams(sort) : undefined, page, pageSize, searchQuery, type)
            .then(response => response.data);
    },
});

export const ingredientsPageLoader = async (queryClient: QueryClient) => {
    const query = ingredientsPageQuery([], '', sortInitialState, 1, 9, []);
    return queryClient.ensureQueryData(query);
};

export const annotationLabelsPageQuery = () => ({
    queryKey: [apiRoutes.materialLibrary.materialEntity.detail.annotationLabels],
    queryFn: () => {
        return openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesAnnotationLabelsRetrieve()
            .then(response => response.data);
    },
});

// ---------------------------------------------------------------------------------------------------------------------

export default function IngredientsPage() {
    const [searchParams, setSearchParams] = useSearchParams();

    const {data: ingredientsAnnotationData, isSuccess: ingredientsAnnotationDataIsSuccess} = useQuery<any>(
        annotationLabelsPageQuery()
    );


    const [filters, setFilters] = useState<string[] | undefined>([]);
    const [searchQuery, setSearchQuery] = useState<string>(searchParams.get("search") ?? ""); // Updated to string
    const [sort, setSort] = useState<SortOption>(sortInitialState);
    const [page, setPage] = useState<number>(1);
    const pageSize = 9;
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [type, setType] = useState<string[] | undefined>(undefined);

    const sortOptions: SortOption[] = [
        {property: 'none', order: "asc", label: 'None'},
        {property: 'date', order: "desc", label: 'Newest'},
        {property: 'date', order: "asc", label: 'Oldest'},
        {property: 'name', order: "desc", label: 'Desc: Name'},
        {property: 'name', order: "asc", label: 'Asc: Name'},
    ];

    const handleQueryByKey = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchQuery(event.target.value);
        setSearchParams(event.target.value ? {"search": event.target.value} : undefined)
    };

    // Handler for changing pages
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
        setPage(page);
    };

    // Handler for changing tabs
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    // Effect to reset the page to 1 whenever search query, type, annotations, or sorting changes
    useEffect(() => {
        setPage(1);
    }, [filters, searchQuery, sort, pageSize, type]);

    const {
        data: ingredientsData,
        isLoading: ingredientsDataIsLoading,
        isSuccess: ingredientsDataIsSuccess
    } = useQuery<any>(
        ingredientsPageQuery(filters, searchQuery, sort, page, pageSize, type)
    );

    return (
        <>
            <Helmet>
                <title>Ingredients | CibusAI</title>
            </Helmet>

            {/* Main content container */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '0px'
            }}>
                {/* Sidebar with Tabs */}
                <Box sx={{width: 320, padding: 0, flexShrink: 0}}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="tabs">
                        <Tab label="Main"/>
                        <Tab label="Annotation"/>
                        <Tab label="Usage & IDs"/>
                    </Tabs>

                    <TabPanel value={tabIndex} index={0}>
                        <Typography
                            gutterBottom
                            sx={{
                                marginTop: 1,
                                mb: 1,
                                color: 'primary.main',
                                fontWeight: 'bold',
                                letterSpacing: '0.5px',
                                fontSize: '14px',
                            }}
                        >
                            Sort By
                        </Typography>
                        <SortIngredientCardList value={sort} setValue={setSort} sortOptions={sortOptions}/>

                        <Typography
                            gutterBottom
                            sx={{
                                mt: 1,
                                mb: 1,
                                color: 'primary.main',
                                fontWeight: 'bold',
                                letterSpacing: '0.5px',
                                fontSize: '14px',
                            }}
                        >
                            Type
                        </Typography>
                        <TypeIngredientCardList
                            selectedType={type}
                            ingredientTypes={ingredientTypesOptions}
                            setSelectedType={setType}
                        />
                        <ClearFilterIngredientCardList value={filters} setValue={setFilters}/>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={1}>
                        <Typography
                            gutterBottom
                            sx={{
                                mt: 1,
                                mb: 1,
                                color: 'primary.main',
                                fontWeight: 'bold',
                                letterSpacing: '0.5px',
                                fontSize: '14px',
                            }}
                        >
                            Taste
                        </Typography>
                        {
                            ingredientsAnnotationDataIsSuccess ?
                                <FilterIngredientCardList value={filters} setValue={setFilters}
                                                          allFilters={ingredientsAnnotationData.TST ?? []}/>
                                : <></>
                        }

                        <Typography
                            gutterBottom
                            sx={{
                                mt: 1,
                                mb: 1,
                                color: 'primary.main',
                                fontWeight: 'bold',
                                letterSpacing: '0.5px',
                                fontSize: '14px',
                            }}
                        >
                            Odor
                        </Typography>
                        {
                            ingredientsAnnotationDataIsSuccess ?
                                <FilterIngredientCardList value={filters} setValue={setFilters}
                                                          allFilters={ingredientsAnnotationData.ODO ?? []}/>
                                : <></>
                        }
                        <ClearFilterIngredientCardList value={filters} setValue={setFilters}/>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={2}>
                        <Typography
                            gutterBottom
                            sx={{
                                mt: 1,
                                mb: 1,
                                color: 'primary.main',
                                fontWeight: 'bold',
                                letterSpacing: '0.5px',
                                fontSize: '14px',
                            }}
                        >
                            Technical Effect
                        </Typography>
                        {
                            ingredientsAnnotationDataIsSuccess ?
                                <FilterIngredientCardList value={filters} setValue={setFilters}
                                                          allFilters={ingredientsAnnotationData.TEC ?? []}/>
                                : <></>
                        }
                        <Typography
                            gutterBottom
                            sx={{
                                mt: 1,
                                mb: 1,
                                color: 'primary.main',
                                fontWeight: 'bold',
                                letterSpacing: '0.5px',
                                fontSize: '14px',
                            }}
                        >
                            Regulatory
                        </Typography>
                        {
                            ingredientsAnnotationDataIsSuccess ?
                                <FilterIngredientCardList value={filters} setValue={setFilters}
                                                          allFilters={ingredientsAnnotationData.REG ?? []}/>
                                : <></>
                        }
                        <Typography
                            gutterBottom
                            sx={{
                                mt: 1,
                                mb: 1,
                                color: 'primary.main',
                                fontWeight: 'bold',
                                letterSpacing: '0.5px',
                                fontSize: '14px',
                            }}
                        >
                            External Identifiers
                        </Typography>
                        {
                            ingredientsAnnotationDataIsSuccess ?
                                <FilterIngredientCardList value={filters} setValue={setFilters}
                                                          allFilters={ingredientsAnnotationData.EXT ?? []}/>
                                : <></>
                        }
                        <ClearFilterIngredientCardList value={filters} setValue={setFilters}/>
                    </TabPanel>
                </Box>

                {/* Main Content Section */}
                <Box sx={{flexGrow: 1, padding: 0}}>
                    <Stack direction="row"
                           alignItems="center"
                           justifyContent="space-between"
                           spacing={2}
                           mb={5}>
                        <Stack direction={"row"} spacing={3}>
                            <StyledSearch
                                fontSize={16}
                                width={400}
                                value={searchQuery}
                                onChange={handleQueryByKey}
                                placeholder="Search Ingredient..."
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search/>
                                    </InputAdornment>
                                }
                            />
                        </Stack>
                    </Stack>

                    <Grid container spacing={3}>
                        {
                            ingredientsDataIsLoading ?
                                <Typography>Is Loading</Typography>
                                :
                                ingredientsDataIsSuccess ?
                                    ingredientsData.results.map((ingredient: MaterialEntity) => (
                                        <Grid item xs={12} sm={6} lg={4} key={ingredient.id}>
                                            <IngredientCard ingredient={ingredient}/>
                                        </Grid>
                                    )) : <Typography>Error Loading the page</Typography>

                        }

                    </Grid>

                    <Grid item xs={12}>
                        <Stack spacing={2} alignItems={"center"}>
                            <Pagination
                                variant="outlined"
                                shape="rounded"
                                count={Math.ceil(ingredientsData ? ingredientsData.count / pageSize : 0)}
                                page={page}
                                onChange={handlePageChange}
                            />
                        </Stack>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

// TabPanel Component
function TabPanel(props: { children?: React.ReactNode; index: number; value: number }) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 2}}>
                    {children}
                </Box>
            )}
        </div>
    );
}
