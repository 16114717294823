import {Helmet} from 'react-helmet-async';
import {SetStateAction, useState} from "react";
// @mui
import {Box, Stack, Tab, Typography,} from '@mui/material';
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {InfoOutlined, TipsAndUpdatesOutlined} from "@mui/icons-material";
// sections
import {IngredientData, IngredientDetails} from "../sections/@dashboard/ingredients";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {apiRoutes} from "../config";
// react router
import {useLocation} from "react-router-dom";
import IngredientAlternatives from '../sections/@dashboard/ingredients/IngredientAlternatives';
import openAPIGeneratorMaterialLibraryInstance from "../openAPIGeneratorMaterialLibraryInstance";
// ----------------------------------------------------------------------
export const ingredientDetailQuery = (ingredientId: number) => ({
        queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, ingredientId.toString()],
        queryFn: () => openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntitiesRetrieve(ingredientId)
            .then(
                response => response.data
            ),
    }
)
export const ingredientDetailLoader = async (queryClient: QueryClient, params: any) => {
    const query = ingredientDetailQuery(params.ingredientId)
    return queryClient.ensureQueryData(query)
}

export const ingredientCompoundDetailQuery = (ingredientId: number) => ({
        queryKey: [apiRoutes.materialLibrary.compounds.baseEndpoint, ingredientId.toString()],
        queryFn: () => openAPIGeneratorMaterialLibraryInstance
            .materialLibraryCompoundsRetrieve(ingredientId)
            .then(
                response => response.data
            ),
    }
)


export default function IngredientDetailPage() {
    // handle tabs
    const [value, setValue] = useState('1');

    // get ingredient
    const location = useLocation();
    const ingredientId = Number(location.pathname.split("/")[2]);
    const handleChange = (event: any, value: SetStateAction<string>) => {
        setValue(value);
    }

    const {isLoading, data: ingredientData} = useQuery<any>(
        ingredientDetailQuery(ingredientId)
    )

    const {isLoading: isIngredientCompoundLoading, data: ingredientCompoundData} = useQuery<any>(
        {
            ...ingredientCompoundDetailQuery(ingredientId),
            enabled: ingredientData ? ingredientData.type == "COM" : false
        }
    )

    return (
        <>
            <Helmet>
                <title> Ingredients | CibusAI </title>
            </Helmet>
            {isLoading ?
                <></>
                :
                <Box>
                    <TabContext value={value}>
                        <Box>
                            <Stack direction={"row"} spacing={3} alignItems={"center"}>
                                <Typography variant={"h4"}>
                                    {ingredientData.name}
                                </Typography>
                                <TabList onChange={handleChange} aria-label="food-tabs">
                                    <Tab icon={<InfoOutlined/>} label="Details" value="1"/>
                                    <Tab icon={<TipsAndUpdatesOutlined/>} label="Insights" value="2"/>
                                    <Tab icon={<TipsAndUpdatesOutlined/>} label="Alternatives" value="3"/>
                                </TabList>
                            </Stack>
                        </Box>
                        <TabPanel sx={{mt: 2, p: 2}} value="1">
                            <IngredientDetails ingredient={ingredientCompoundData ?? ingredientData}/>
                        </TabPanel>
                        <TabPanel sx={{mt: 2, p: 2}} value="2">
                            <IngredientData ingredient={ingredientCompoundData ?? ingredientData}/>
                        </TabPanel>
                        <TabPanel sx={{mt: 2, p: 2}} value="3">
                            <IngredientAlternatives ingredient={ingredientCompoundData ?? ingredientData}/>
                        </TabPanel>
                    </TabContext>
                </Box>}
        </>
    );
}
