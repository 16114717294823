import {alpha, createTheme, styled, Theme} from "@mui/material/styles";
import {OutlinedInput} from "@mui/material";
import customShadows from "../../theme/customShadows";

const theme: Theme = createTheme();
const StyledSearch = styled(OutlinedInput)((props: { fontSize?: number, width?: number }) => ({
    fontSize: props.fontSize,
    width: props.width,
    marginRight: 4,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: props.width ? Math.round(props.width * 1.3) : undefined,
        boxShadow: customShadows.call(customShadows().z8),
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

export default StyledSearch;