import {Avatar, Card, CardActionArea, CardContent, Stack, Typography} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";

export default function OverviewCard(props: {
    title: any,
    value: any,
    subTitle?: any,
    subValue?: number | { label: string, value: number }[],
    children: any,
    color: any,
    sx: any,
    to?: any
}) {

    return (
        <Card sx={props.sx}>
            <CardActionArea component={RouterLink}
                            to={props.to}>
                <CardContent>
                    <Stack
                        alignItems="flex-start"
                        direction="row"
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Stack spacing={1}>
                            <Typography
                                color="text.secondary"
                                variant="overline"
                            >
                                {props.title}
                            </Typography>
                            <Typography variant="h4">
                                {props.value}
                            </Typography>
                        </Stack>
                        <Avatar
                            sx={{
                                backgroundColor: props.color,
                                height: 56,
                                width: 56
                            }}
                        >
                            {props.children}
                        </Avatar>
                    </Stack>
                    {typeof props.subValue === 'number' ? (
                        <Stack spacing={1} mt={2}>
                            <Typography
                                color={props.color}
                                variant="body2"
                            >
                                {props.subValue} {props.subTitle}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack spacing={1} mt={2}>
                            {props.subValue && props.subValue.map((sub, index) => (
                                <Typography
                                    key={index}
                                    color={props.color}
                                    variant="body2"
                                >
                                    {sub.value} {sub.label}
                                </Typography>
                            ))}
                        </Stack>
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
