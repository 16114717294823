import {styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import {Box, Button, Typography} from "@mui/material";
import {NavLink as RouterLink} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import openAPIGeneratorFoodDesignerInstance from "../../../openAPIGeneratorFoodDesignerInstance";
import {CreateNestedFormulation, CreateProduct, MaterialEntity} from "../../../api";

const PrimaryConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 32px)',
        right: 'calc(50% + 32px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 4,
        borderRadius: 1,
    },
}));

const PrimaryStepIconRoot: any = styled('div')(({ theme, ownerState }: any) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: theme.palette.primary.main,
    }),
    '& .PrimaryStepIcon-completedIcon': {
        color: theme.palette.primary.main,
        zIndex: 1,
        fontSize: 28,
    },
    '& .PrimaryStepIcon-circle': {
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function PrimaryStepIcon(props: any) {
    const { active, completed, className } = props;

    return (
        <PrimaryStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="PrimaryStepIcon-completedIcon" />
            ) : (
                <div className="PrimaryStepIcon-circle" />
            )}
        </PrimaryStepIconRoot>
    );
}

//const steps = ['1. Add a description', '2. Add the ingredients', '3. Validate the ingredients'];
const steps = ['1. Add a description', '2. Add the ingredients'];
const stepsLength = steps.length;

function CleanUpFoodProducts(foodProduct: any) {

    const arrayOfIds: number[] = foodProduct.ingredients.map((ingredient: MaterialEntity) => ingredient.id);
    const formulation: CreateNestedFormulation =
        {
            name: foodProduct.name,
            description: foodProduct.description,
            ingredients: arrayOfIds,
            date_created: foodProduct.date_created,
            date_modified: foodProduct.date_modified,
            type: "CPX",
            calories: foodProduct.calories,
            serving: foodProduct.serving,
            serving_unit: foodProduct.serving_unit,
            price: foodProduct.price,
            price_unit: foodProduct.price_unit,
            nutritional_score: foodProduct.nutritional_score
        }

    let formulations: Array<CreateNestedFormulation> = [formulation]

    const product: CreateProduct =
        {
            formulations: formulations,
            name: foodProduct.name,
            description: formulation.description,
            date_created: formulation.date_created,
            date_modified: formulation.date_modified
        }

    return product;
}

export default function NewFoodStepper(props: {activeStep: any, setActiveStep: any, state: any, children: any}) {


    const foodProductMutation: any = useMutation({
    mutationFn: () => {
      //return axiosBackendInstance.post(apiRoutes.foodDesigner.products.baseEndpoint, CleanUpIngredients(props.state))
        console.log(CleanUpFoodProducts(props.state))
      return openAPIGeneratorFoodDesignerInstance.foodDesignerProductsCreate(CleanUpFoodProducts(props.state))
    },
  })

    return (
        <Stack direction={"column"} justifyContent={'space-between'} spacing={4} sx={{minHeight: '70vh'}}>
            <Stepper alternativeLabel
                     activeStep={props.activeStep}
                     connector={<PrimaryConnector />}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={PrimaryStepIcon}>
                            <Typography variant={"subtitle2"}>
                                {label}
                            </Typography>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            {props.children}
            <Box>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Button variant={'outlined'}
                            size={'large'}
                            disabled={props.activeStep === 0}
                            onClick={() => props.setActiveStep((prevActiveStep: any) => prevActiveStep - 1)}>
                        Back
                    </Button>
                    {props.activeStep === stepsLength - 1 ?
                        <Button variant={'outlined'}
                                size={'large'}
                                color={'success'}
                                type={'submit'}
                                onClick={foodProductMutation.mutate}
                                component={RouterLink}
                                to={"/food-products"}>
                            Submit
                        </Button> :
                        <Button variant={'outlined'}
                                size={'large'}
                                disabled={props.activeStep === stepsLength - 1}
                                onClick={() => props.setActiveStep((prevActiveStep: any) => prevActiveStep + 1)}>
                            Next
                        </Button>
                    }
                </Stack>
            </Box>
        </Stack>
    );
}