import {MaterialEntity, MaterialEntityAnnotation} from "../api";
import {groupBy} from "./grouping";
import {annotationTypes} from "../config";

export function filter_annotations_by_type_set(materialEntity: MaterialEntity, typesSet: Set<string>) {
    return materialEntity.annotations ? materialEntity.annotations
            .filter((annotation: any) => typesSet.has(annotation.type))
            // .map((annotation: any) => {
            //     return {
            //         ...annotation, label: annotation.label.toLowerCase()
            //     }
            // })
        : []
}

export function uniqueAnnotationsLabels(annotations: MaterialEntityAnnotation[]) {
    return annotations.filter((obj1: MaterialEntityAnnotation, i: number, arr: MaterialEntityAnnotation[]) =>
        arr.findIndex(obj2 => (obj2.label === obj1.label)) === i
    )
}

export function uniqueAnnotationsGroupedByType(annotations: MaterialEntityAnnotation[]) {
    const annotationsColumns: any = {}

    const annotationsGroupedByType = groupBy(annotations, "type")
    Object.entries<any>(annotationsGroupedByType).forEach(([label, annotations]): any => {
        annotationsColumns[annotationTypes[label]] = uniqueAnnotationsLabels(annotations).map((annotation: any) => ({
            key: annotation.id,
            label: annotation.label,
            value: annotation.value
        }))
    })
    return annotationsColumns
}
