import React, {useMemo} from "react";
// @mui/material
import {Chip, Grid, Stack, Tooltip, Typography,} from "@mui/material";
import {Article, AutoGraph, Science} from "@mui/icons-material";
// material react table
import {MaterialReactTable} from "material-react-table";
import {annotationTypes, annotationTypeSets} from "../../../config";
import {MaterialEntity} from "../../../api";
import {filter_annotations_by_type_set, uniqueAnnotationsGroupedByType} from "../../../utils/material_entities";
import {ChipStack} from "../../../components/chip-stacks";
import MRTButtonStack, {IngredientDetailButton} from "../../../components/ingredient-buttons/IngredientButtons";


export function getLogisticsColumns(ingredient: MaterialEntity) {
    const insights = filter_annotations_by_type_set(ingredient, annotationTypeSets.logistics)
    return uniqueAnnotationsGroupedByType(insights);
}


export function ChipEvidenceOrPrediction(props: { insight: any }) {
    if (props.insight.value === 'Experimental Evidence') {
        return (
            <Tooltip title={props.insight.value} followCursor>
                <Chip label={props.insight.label}
                      color={"success"}
                      variant={"outlined"}
                      icon={<Science/>}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else if (typeof props.insight.value === 'string') {
        return (
            <Tooltip title={props.insight.value} followCursor>
                <Chip label={props.insight.label}
                      color={"success"}
                      variant={"outlined"}
                      icon={<Article/>}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else if (props.insight.value > 75) {
        return (
            <Tooltip title={`Predicted ${props.insight.value}`} followCursor>
                <Chip label={props.insight.label}
                      color={"primary"}
                      variant={"outlined"}
                      icon={<AutoGraph/>}
                      component="a"
                      href="#"
                      size={"small"}
                />
            </Tooltip>
        )
    } else {
        return null
    }
}


// ----------------------------------------------------------------------
export function ChipStackEvidencePrediction(props: { insightsValues: any }) {

    return (
        <Stack direction={'row'} spacing={1}>
            {props.insightsValues.length > 3 ?
                <>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[0]} key={props.insightsValues[0].key}/>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[1]} key={props.insightsValues[1].key}/>
                    <ChipEvidenceOrPrediction insight={props.insightsValues[2]} key={props.insightsValues[2].key}/>
                    <Chip label={"+" + (props.insightsValues.length - 3)}
                          size={"small"}
                          key={"more"}
                    />
                </>
                :
                props.insightsValues.map((insight: any) => <ChipEvidenceOrPrediction insight={insight}
                                                                                     key={insight.key}/>)
            }
        </Stack>
    )
}

export function getInsightsColumns(ingredient: MaterialEntity) {
    const insights = filter_annotations_by_type_set(ingredient, annotationTypeSets.insights)
    return uniqueAnnotationsGroupedByType(insights);
}

// ----------------------------------------------------------------------
export function IngredientsPropertyTable(props: {
    food: MaterialEntity
}) {
    const data = useMemo(
        () => props.food.ingredients.map((ingredient: any) => ({
            id: ingredient.id,
            name: ingredient.name,
            type: ingredient.type,
            ...getLogisticsColumns(ingredient)
        })),
        [props.food.ingredients],
    );


    const columns = useMemo(
        () => {
            return [
                {
                    accessorKey: 'name',
                    header: 'Name',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    size: 150,
                    Cell: ({renderedCellValue}: any) => <Typography
                        variant={"subtitle2"}>{renderedCellValue}</Typography>,
                },
                ...[...annotationTypeSets.logistics].map((key) => ({
                    accessorKey: annotationTypes[key],
                    header: annotationTypes[key],
                    columnFilterModeOptions: ['fuzzy'],
                    size: 100,
                    Cell: ({renderedCellValue}: any) => renderedCellValue ?
                        <ChipStack stringArray={renderedCellValue.map((obj: any) => obj.label)}/> : <></>
                }))
            ]
        },
        [],
    );


    return <MaterialReactTable columns={columns}
                               data={data}
                               enableColumnFilterModes
                               enableRowActions
                               renderRowActions={({row}) => (
                                   <MRTButtonStack>
                                       <IngredientDetailButton
                                           foodName={props.food.name}
                                           ingredientUID={row.original.id}
                                           ingredientName={row.original.name}
                                           ingredientType={row.original.type}
                                           contentUID={row.original.id}
                                       />
                                       {/*<IngredientAlternativeButton*/}
                                       {/*    foodName={props.food.name}*/}
                                       {/*    ingredientUID={row.original.id}*/}
                                       {/*    ingredientName={row.original.name}*/}
                                       {/*    ingredientType={row.original.type}*/}
                                       {/*    contentUID={row.original.id}*/}
                                       {/*/>*/}
                                   </MRTButtonStack>
                               )}/>
}

// ----------------------------------------------------------------------
export default function FoodInsightsSection(props: { food: any }) {

    const columns: any = useMemo(
        () => {
            return [
                {
                    accessorKey: 'name',
                    header: 'Name',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    size: 150,
                    Cell: ({renderedCellValue}: any) => <Typography
                        variant={"subtitle2"}>{renderedCellValue}</Typography>,
                },
                ...[...annotationTypeSets.insights].map((key) => ({
                    accessorKey: annotationTypes[key],
                    header: annotationTypes[key],
                    columnFilterModeOptions: ['fuzzy'],
                    size: 100,
                    Cell: ({renderedCellValue}: any) => renderedCellValue ?
                        <ChipStackEvidencePrediction insightsValues={renderedCellValue}/> : <></>
                })),
            ]
        },
        [],
    );

    const data = useMemo(
        () => {
            return props.food.ingredients.map((ingredient: MaterialEntity) => ({
                id: ingredient.id,
                name: ingredient.name,
                type: ingredient.type,
                ...getInsightsColumns(ingredient)
            }))
        },
        [props.food.ingredients],
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant={"h5"} color={"primary"} paragraph>
                    Technical Insights
                </Typography>
                <MaterialReactTable columns={columns}
                                    data={data}
                                    enableColumnFilterModes
                                    enableRowActions
                                    renderRowActions={({row}: any) => {
                                        return <MRTButtonStack>
                                            <IngredientDetailButton
                                                foodName={props.food.name}
                                                ingredientUID={row.original.id}
                                                ingredientName={row.original.name}
                                                ingredientType={row.original.type}
                                                contentUID={row.original.id}
                                            />
                                            {/*<IngredientAlternativeButton*/}
                                            {/*    foodName={props.food.name}*/}
                                            {/*    ingredientUID={row.original.id}*/}
                                            {/*    ingredientName={row.original.name}*/}
                                            {/*    ingredientType={row.original.type}*/}
                                            {/*    contentUID={row.original.id}*/}
                                            {/*/>*/}
                                        </MRTButtonStack>
                                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"h5"} color={"primary"} paragraph>
                    Properties & Labels
                </Typography>
                <IngredientsPropertyTable food={props.food}/>
            </Grid>
        </Grid>
    )
}