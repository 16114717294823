export function groupBy<Type> (items: Type[], key: string) {
    return items.reduce(
        (result: any, item: any) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );
}