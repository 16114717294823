// @mui
import {Box, Grid, Stack, Typography} from "@mui/material";
import {CalendarToday, Extension, MonetizationOnOutlined,} from "@mui/icons-material";
// components
import {ScoreAvatar} from "../nutritional-score-avatar";
import {ChipStack} from "../chip-stacks";
// utils
import {fDate} from "../../utils/formatTime";
import {imageFallBacks, nutritionConfig} from "../../config";
import {DailyPercentageIntakeWheelWithLabel} from "../progress-label/CircularProgressLabel";
import {MaterialEntity} from "../../api";

// ----------------------------------------------------------------------
export default function FoodInfoCard(props: {food: any}) {

    const foodIngredients = props.food.ingredients.map((ingredient: MaterialEntity) => ingredient.name);

    return (
        <Grid container
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={2}>
            <Grid item xs={4} lg={2}>
                <img
                    src={props.food.img ? `${props.food.img}` : imageFallBacks.foodproduct}
                    alt={props.food.name}
                    height={200}/>
            </Grid>
            <Grid item xs={8} lg={4}>
                <Typography paragraph>
                    {props.food.description ?? ""}
                </Typography>
                <Stack direction="column" spacing={2}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <CalendarToday/>
                        <Typography sx={{paddingLeft: 1}} variant="body2" color="text.secondary">
                            {fDate(props.food.date_created)}
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <MonetizationOnOutlined/>
                        <Typography sx={{paddingLeft: 1}} variant="body2" color="text.secondary">
                            {props.food.price} €
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Extension/>
                        <ChipStack stringArray={foodIngredients}/>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={4} lg={2}>
                <Stack alignItems={'center'}>
                    <Typography variant={"h6"} align={'center'} paragraph>
                        Calories
                    </Typography>
                    <DailyPercentageIntakeWheelWithLabel value={props.food.calories}
                                                         reference={nutritionConfig.adultDailyCalorieIntake}
                                                         size={100}
                                                         unit={'kcal'}
                    />
                </Stack>
            </Grid>
            <Grid item xs={4} lg={2}>
                <Typography variant={"h6"} align={'center'} paragraph>
                    Nutritional Score
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <ScoreAvatar score={props.food.nutritional_score ? props.food.nutritional_score : "?"}/>
                </Box>
            </Grid>
            <Grid item xs={4} lg={2}>
                <Typography variant={"h6"} align={'center'} paragraph>
                    Eco Score
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <ScoreAvatar score={props.food.sustainability_score ? props.food.sustainability_score : "?"}/>
                </Box>
            </Grid>
        </Grid>
    )
}