import React, {useState} from "react";

// @mui
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {ExpandMore, Search} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
// components
import {IngredientButtons} from "../ingredient-buttons";
import {ChipStack} from "../chip-stacks";
import {StyledSearch} from "../search";
// utils
import {applySortFilter, getComparator} from "../../utils/filteringAndSorting";
import {MaterialEntity} from "../../api";
import MRTButtonStack, {
    IngredientAlternativeButton,
    IngredientDetailButton, IngredientRemoveButton
} from "../ingredient-buttons/IngredientButtons";

// ----------------------------------------------------------------------
function IngredientItem(props: { food?: any, ingredient?: any }) {

    const foodName = props.food ? props.food.name : "Not Available"
    const ingredientUID = props.ingredient ? props.ingredient.id : "Not Available"
    const ingredientName = props.ingredient ? props.ingredient.name : "Not Available"
    const ingredientValue = props.ingredient.value ? props.ingredient.value : "Not Available"

    return (
        <>
            {foodName ?
                <ListItem key={ingredientUID}
                          disablePadding
                          disableGutters
                          secondaryAction={
                              <MRTButtonStack>
                                  <IngredientDetailButton
                                      foodName={foodName}
                                      ingredientUID={ingredientUID}
                                      ingredientName={ingredientName}
                                      ingredientType={ingredientValue}
                                  />
                              </MRTButtonStack>
                          }>
                    <ListItemText primary={ingredientName}
                                  secondary={`${ingredientValue} g`}>
                    </ListItemText>
                </ListItem>
                :
                <ListItem key={ingredientUID}>
                    <ListItemText primary={ingredientName}
                                  secondary={`${ingredientValue} g`}>
                    </ListItemText>
                </ListItem>
            }
        </>
    )
}

// ----------------------------------------------------------------------
const ExpandMoreButton = styled((props: any) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

// ----------------------------------------------------------------------

export default function MacronutrientCard(props: { food?: any, macronutrient?: any }) {
    const [filterName, setFilterName] = useState('');

    const sort = {
        property: 'newest',
        order: 'desc',
        label: 'Newest'
    }

    const handleFilterByName = (event: any) => {
        setFilterName(event.target.value);
    };

    const filteredIngredients = applySortFilter(
        props.macronutrient.ingredients,
        getComparator(sort.order, sort.property),
        filterName
    );

    // collapse
    const [expanded, setExpanded] = useState(false);

    const handleExpand = () => {
        setExpanded(!expanded)
    }

    function prepString(name: any) {
        return name.length > 10 ? name.substring(0, 8) + ".." : name;
    }

    return (
        <Card variant={"outlined"}
              sx={{
                  maxWidth: 300,
                  minHeight: 200,
              }}>
            <CardHeader
                // avatar={<CircularProgressLabel value={macronutrient.value} size={60} unit={'%'} />}
                title={props.macronutrient.name}
                sx={{pb: 2}}
            />
            <Divider variant="middle"/>
            <CardContent>
                <Typography variant={"body1"} align={"left"} paragraph>Ingredients</Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <ChipStack
                        stringArray={props.macronutrient.ingredients.map((ingredient: MaterialEntity) => prepString(ingredient.name))}/>
                    <ExpandMoreButton
                        expand={expanded}
                        onClick={handleExpand}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMore/>
                    </ExpandMoreButton>
                </Stack>
                <Box mt={2}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <StyledSearch
                            fontSize={14}
                            width={180}
                            value={filterName}
                            onChange={handleFilterByName}
                            placeholder="Search ingredients..."
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search/>
                                </InputAdornment>
                            }
                        />
                        <List dense={true} sx={{width: '100%', maxWidth: 300}}>
                            {filteredIngredients.map((ingredient: MaterialEntity) => (
                                <IngredientItem food={props.food} ingredient={ingredient}/>
                            ))}
                        </List>
                    </Collapse>
                </Box>
            </CardContent>
        </Card>
    );
}