import React, {Fragment, useEffect, useRef, useState} from "react";
// @mui
import {
    Avatar, Box, Button,
    Card,
    CardActions,
    CardContent, Dialog, DialogContent, DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Skeleton,
    Stack, Tab,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ChatBubbleOutline,
    ChatOutlined, CloseOutlined,
    ContentCopy, HistoryOutlined,
    SendOutlined, SettingsOutlined,
    ThumbDownOutlined,
    ThumbUpOutlined,
} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
// @azure/msal-react
import {useMsal} from "@azure/msal-react";
import openAPIGeneratorChatInstance from "../../openAPIGeneratorChatInstance";
import Markdown from 'react-markdown'
import {stringInitials} from "../../utils/userName";
import {fallBackUserName} from "../../config";

//-------------------------------------------------------------------------------------------------------------------

function AssistantMessage(props: { message: any; index: any }) {
    const [goodResponse, setGoodResponse] = useState(false);
    const [badResponse, setBadResponse] = useState(false);
    const [copy, setCopy] = useState(false);

    const handleGoodResponse = () => {
        setGoodResponse(true);
        setBadResponse(false);
    };

    const handleBadResponse = () => {
        setGoodResponse(false);
        setBadResponse(true);
    };

    const handleCopy = () => {
        setCopy(true);
        navigator.clipboard.writeText(props.message.content);
    };

    return (
        <Fragment key={props.index}>
            <Grid item xs={1}/>
            <Grid item xs={11}>
                <Box sx={{maxWidth: '100%'}}>
                    <Card sx={{width: '100%'}}>
                        <CardContent>
                            {props.message.content === '...' ? (
                                <Skeleton animation="wave" height={40}/>
                            ) : (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'pre-wrap'
                                    }}>
                                    <Markdown>
                                        {props.message.content}
                                    </Markdown>
                                </Typography>
                            )}
                        </CardContent>
                        <CardActions>
                            <Tooltip title="Good response">
                                <IconButton aria-label="good response" onClick={handleGoodResponse}>
                                    {goodResponse ? (
                                        <ThumbUpOutlined fontSize="small" color="primary"/>
                                    ) : (
                                        <ThumbUpOutlined fontSize="small"/>
                                    )}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bad response">
                                <IconButton aria-label="bad response" onClick={handleBadResponse}>
                                    {badResponse ? (
                                        <ThumbDownOutlined fontSize="small" color="primary"/>
                                    ) : (
                                        <ThumbDownOutlined fontSize="small"/>
                                    )}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Copy">
                                <IconButton aria-label="copy" onClick={handleCopy} disabled={copy}>
                                    <ContentCopy fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    </Card>
                </Box>
            </Grid>
        </Fragment>
    );
}


function UserMessage(props: { message: any; index: any; accounts: any }) {
    const theme = useTheme();
    const account = props.accounts[0];

    return (
        <Fragment key={props.index}>
            <Grid item xs={11}>
                <Box sx={{maxWidth: '100%'}}>
                    <Card sx={{width: '100%'}}>
                        <CardContent>
                            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
                                <Avatar
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        width: 38,
                                        height: 38,
                                    }}
                                    aria-label="avatar"
                                >
                                    {stringInitials(account.name ?? fallBackUserName)}
                                </Avatar>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'pre-wrap'
                                    }}>
                                    {props.message.content}
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs={1}/>
        </Fragment>
    );
}


function PlaceHolderMessage() {
    return (
        <Grid item xs={12}>
            <Box sx={{maxWidth: '100%'}}>
                <Card sx={{width: '100%'}}>
                    <CardContent>
                        <Typography variant="body2">
                            Hi, I am <strong>Cibus</strong>.<br/>
                            Your food reformulation assistant.<br/>
                            I'm here to help, <strong>ask me anything</strong>.
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    );
}


function ChatField(props: { label: any; message: any; handleChange: any; handleSend: any }) {
    return (
        <Grid item xs={12} style={{overflow: 'hidden'}}>
            <Divider sx={{my: 2}}/>
            <TextField
                fullWidth
                color="primary"
                multiline
                minRows={1}
                label={props.label}
                id="chat"
                value={props.message}
                onChange={props.handleChange}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter' && !ev.shiftKey) {
                        ev.preventDefault();
                        props.handleSend();
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <ChatBubbleOutline/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton type="submit" onClick={props.handleSend} aria-label="send" edge="end">
                                <SendOutlined/>
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        overflow: 'auto',
                        whiteSpace: 'pre-wrap',
                        textOverflow: 'break-word',
                    },
                }}
            />
        </Grid>
    );
}


export function ChatBot(props: { conversation: any; message: any; label: any; handleChange: any; handleSend: any }) {
    const {accounts} = useMsal();

    return (
        <Grid container spacing={1} sx={{padding: 1}}>
            {props.conversation.length > 1 ? (
                <Fragment>
                    {props.conversation.map((message: any, index: any) => (
                        message.role === 'assistant' ? (
                            <AssistantMessage key={index} message={message} index={index}/>
                        ) : (
                            <UserMessage key={index} message={message} index={index} accounts={accounts}/>
                        )
                    ))}
                </Fragment>
            ) : (
                <PlaceHolderMessage/>
            )}
        </Grid>
    );
}

//----------------------------------------------------------------------------------------------------------------------

export function ChatUI(props: { handleDrawerOpen: any, handleDrawerClose: any }) {
    const [chatConversation, setChatConversation] = useState<any[]>([]);
    const [chatMessage, setChatMessage] = useState<string>('');
    const [chatLabel, setChatLabel] = useState<string>('0/500');
    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
    const [historyOpen, setHistoryOpen] = useState<boolean>(false);

    // Reference to the bottom of the chat
    const chatEndRef = useRef<HTMLDivElement | null>(null);

    const handleChatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChatMessage(event.target.value);
        setChatLabel(`${event.target.value.length}/500`);
    };

    const handleChatSendMessage = async () => {
        if (chatMessage === '') return;

        const newCompletion: { role: string, content: string } = {role: 'user', content: chatMessage};

        setChatConversation([...chatConversation, newCompletion, {role: 'assistant', content: '...'}]);
        setChatMessage('');
        setChatLabel('0/500');

        const formData = {
            messages: [...chatConversation, newCompletion], tools_selected: [
                "PriceChecker",
                "MolSimilarity",
                "FuncGroups",
                "GHS_classification",
                "Query2Smiles",
                "QueryCompoundAnnotations",
                "MoleculeDescriptor",
                "Mol2Weight",
                "Mol2Formula",
                "Mol2IUPAC",
                "Mol2Inchi",
                "PatentChecker",
                "ChemicalCompoundInformation",
                "FoodRegulationsAnswer"
            ]
        }
        try {
            const {data} = await openAPIGeneratorChatInstance.assistantCompletionCreate(formData);
            if (data && data.messages) {
                setChatConversation(data.messages);
            }
        } catch (error) {
            setChatConversation([...chatConversation, newCompletion, {
                role: 'assistant',
                content: 'An error has occurred. Please contact the administrator',
            }]);
        }
    };

    const clearChatConversation = () => {
        setChatConversation([]);
        setChatMessage('');
        setChatLabel('0/500');
    };

    const handleSettingsOpen = () => {
        setSettingsOpen(true);
    };

    const handleSettingsClose = () => {
        setSettingsOpen(false);
    };

    const handleHistoryOpen = () => {
        setHistoryOpen(true);
    };

    const handleHistoryClose = () => {
        setHistoryOpen(false);
    };

    const handleClose = () => {
        props.handleDrawerClose();
    };

    // Scroll to the bottom whenever chatConversation changes
    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [chatConversation]);

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{
                width: '100%',
                padding: 0.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Box>
                    <IconButton onClick={handleSettingsOpen} aria-label="settings">
                        <SettingsOutlined/>
                    </IconButton>
                    <IconButton onClick={handleHistoryOpen} aria-label="history">
                        <HistoryOutlined/>
                    </IconButton>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {chatConversation.length > 0 && (
                        <Button variant="outlined" onClick={clearChatConversation}>
                            New Chat
                        </Button>
                    )}
                    <IconButton onClick={handleClose} aria-label="close" sx={{ml: 1}}>
                        <CloseOutlined/>
                    </IconButton>
                </Box>
            </Box>

            {/* Middle chat area that should expand to fill available space */}
            <Box
                sx={{p: 1, height: 'calc(100vh - 230px)', overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
                <Box sx={{flex: 1, overflowY: 'auto'}}>
                    <ChatBot
                        conversation={chatConversation}
                        message={chatMessage}
                        label={chatLabel}
                        handleChange={handleChatChange}
                        handleSend={handleChatSendMessage}
                    />
                    {/* Dummy element to mark the end of the chat */}
                    <div ref={chatEndRef}></div>
                </Box>
            </Box>

            {/* Bottom input field */}
            <Box sx={{p: 1}}>
                <ChatField
                    label={chatLabel}
                    message={chatMessage}
                    handleChange={handleChatChange}
                    handleSend={handleChatSendMessage}
                />
            </Box>

            {/* Settings Dialog */}
            <Dialog open={settingsOpen} onClose={handleSettingsClose}>
                <DialogTitle>Settings</DialogTitle>
                <DialogContent>
                    <p>No chat settings available.</p>
                </DialogContent>
            </Dialog>

            {/* Chat History Dialog */}
            <Dialog open={historyOpen} onClose={handleHistoryClose}>
                <DialogTitle>Chat History</DialogTitle>
                <DialogContent>
                    <p>No chat history available.</p>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
