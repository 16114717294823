import {Helmet} from 'react-helmet-async';
// @mui
import {Box, Grid} from '@mui/material';
import {Extension, HourglassBottom, LunchDining, MenuBook} from "@mui/icons-material";
// components
import OverviewCard from "../components/overview-card";
import {LatestIngredients} from "../sections/@dashboard/overview";
import {useQuery} from "@tanstack/react-query";
import {apiRoutes} from "../config";
import LatestFoodProducts from "../sections/@dashboard/overview/LatestFoodProducts";
import openAPIGeneratorCoreInstance from "../openAPIGeneratorCoreInstance";
import OverviewSearch from "../components/overview-search";

// ----------------------------------------------------------------------

export const overviewPageQuery = () => ({
    queryKey: [apiRoutes.core.overviewEndpoint],
    queryFn: async () => openAPIGeneratorCoreInstance
        .coreOverviewRetrieve()
        .then(
            response => response.data
        ),
})


export const overviewPageLoader = async (queryClient: any) => {
    const query = overviewPageQuery()
    return queryClient.ensureQueryData(query)
}


export default function OverviewPage() {
    const {isLoading, data: overviewData} = useQuery<any>(
        overviewPageQuery()
    )


    const subValues: { label: string, value: number }[] = [
        { label: 'Small Molecules', value: overviewData.material_library.count_by_type.COM == null ? 0 : overviewData.material_library.count_by_type.COM},
        { label: 'Complex', value: overviewData.material_library.count_by_type.CPX == null ? 0 : overviewData.material_library.count_by_type.CPX },
        { label: 'Protein', value: overviewData.material_library.count_by_type.PRO == null ? 0 : overviewData.material_library.count_by_type.PRO},
        { label: 'Other', value: overviewData.material_library.count_by_type.OTH == null ? 0 : overviewData.material_library.count_by_type.OTH}
    ];

    return (
        <>
            <Helmet>
                <title> Overview | CibusAI </title>
            </Helmet>
            <Box>
                {isLoading || !overviewData ? <></> :
                    <Grid container spacing={3} alignItems="stretch">
                        <Grid item xs={12} md={12} lg={12}>
                            <OverviewSearch
                                name={"Ingredient Search"}
                                description={"Search for relevant ingredients, by name or description"}
                                section={"ingredients"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <OverviewCard
                                title={'Food Products'}
                                value={overviewData.food_products.total_count}
                                subTitle={'is the average number of formulations'}
                                subValue={overviewData.food_products.average_formulation_count == null ? 0 : overviewData.food_products.average_formulation_count}
                                color={"error.main"}
                                sx={{height: '100%'}}
                                to={"/food-products"}
                            >
                                <LunchDining/>
                            </OverviewCard>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <OverviewCard
                                title={'Ingredients'}
                                value={overviewData.material_library.total_count}
                                subTitle={"Material Library"}
                                subValue={subValues}
                                color={"success.main"}
                                sx={{height: '100%'}}
                                to={"/ingredients"}
                            >
                                <Extension/>
                            </OverviewCard>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <LatestFoodProducts
                                name="Latest Food Products"
                                description={"Check the last 5 food products"}
                                list={overviewData.food_products.latest.slice(0, 5)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <LatestIngredients
                                name="Latest Ingredients"
                                description={"Check the last 5 ingredients"}
                                list={overviewData.material_library.latest.slice(0, 5)}
                            />
                        </Grid>
                    </Grid>
                }
            </Box>
        </>
    );
}
