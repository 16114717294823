import {Helmet} from 'react-helmet-async';
import {useState} from "react";
// @mui
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Input,
    Stack,
    Tab,
    TextField,
    Typography
} from '@mui/material';
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {ExtensionOutlined, InfoOutlined, TipsAndUpdatesOutlined,} from "@mui/icons-material";
// router
import {useLocation} from "react-router-dom";
// components and sections
import {FoodDetails, FoodIngredientsSection, FoodInsightsSection} from "../sections/@dashboard/foods";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {apiRoutes} from "../config";
import openAPIGeneratorFoodDesignerInstance from "../openAPIGeneratorFoodDesignerInstance";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {CreateProduct, Product} from "../api";
import openAPIGeneratorMaterialLibraryInstance from "../openAPIGeneratorMaterialLibraryInstance";

// ----------------------------------------------------------------------

export const foodDetailPageQuery = (foodId: number) => ({
        queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, foodId.toString()],
        queryFn: () => openAPIGeneratorFoodDesignerInstance
            .foodDesignerProductsRetrieve(foodId)
            .then(
                response => response.data
            ),
    }
)

export const formulationDetailPageQuery = (formulationId: number) => ({
        queryKey: [apiRoutes.foodDesigner.formulations.baseEndpoint, formulationId.toString()],
        queryFn: () => formulationId ? openAPIGeneratorFoodDesignerInstance
            .foodDesignerFormulationsRetrieve(formulationId)
            .then(
                response => response.data
            ) : [],
    }
)

export const foodDetailPageLoader = async (queryClient: any, params: any) => {
    const query = foodDetailPageQuery(params.foodId)
    return queryClient.ensureQueryData(query)
}

function EditFoodMetadata(props: { food: Product }) {
    const {
        control,
        handleSubmit,
    } = useForm<Product>(
        {
            defaultValues: {
                name: props.food.name,
                description: props.food.description,
            },
        }
    )
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const queryClient = useQueryClient()

    const foodMetadataMutation: any = useMutation({
        mutationFn: (food: CreateProduct) => {
            return openAPIGeneratorFoodDesignerInstance.foodDesignerProductsUpdate(props.food.id, food)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.foodDesigner.products.baseEndpoint, props.food.id.toString()]})
        },
    })

    const onSubmit: SubmitHandler<Product> = (data) => {
        console.log(data)
        foodMetadataMutation.mutate(data)
        handleClose();
    }

    return (
        <>
            <Button onClick={handleOpen}>Edit Product</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    // @ts-ignore
                    component: "form",
                    onSubmit: handleSubmit(onSubmit),
                }}>
                <DialogTitle>Edit Product Metadata</DialogTitle>
                <DialogContent>
                    <Controller
                        name="name"
                        control={control}
                        render={({field}) => <TextField
                            variant="outlined"
                            label={"Name"}
                            fullWidth
                            margin="normal"
                            {...field} />}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({field}) => <TextField
                            variant="outlined"
                            label={"Description"}
                            fullWidth
                            margin="normal"
                            multiline
                            maxRows={8}
                            {...field} />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default function FoodDetailPage() {
    // handle tabs
    const [value, setValue] = useState('1');

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    // get macronutrients from food
    const location = useLocation();
    const foodId = Number(location.pathname.split("/")[2]);

    const {isLoading: isLoadingFoodData, data: foodProductData} = useQuery<any>(
        foodDetailPageQuery(foodId)
    )

    const formulationId = foodProductData?.formulations[0].id

    const {isLoading: isLoadingFormulationData, data: formulationData} = useQuery<any>(
        formulationDetailPageQuery(formulationId)
    )

    return (
        <>
            <Helmet>
                <title> Foods | CibusAI </title>
            </Helmet>
            {isLoadingFoodData ?
                <></>
                :
                <Box>
                    <Typography variant={"h4"} paragraph>
                        {foodProductData.name} <EditFoodMetadata food={foodProductData}/>
                    </Typography>
                    {isLoadingFormulationData ?
                        <></>
                        :
                        <TabContext value={value}>
                            <Box>
                                <TabList onChange={handleChange} aria-label="food-tabs">
                                    <Tab icon={<InfoOutlined/>} label="Details" value="1"/>
                                    <Tab icon={<TipsAndUpdatesOutlined/>} label="Insights" value="2"/>
                                </TabList>
                            </Box>
                            <TabPanel sx={{p: 2}} value="1">
                                <FoodDetails food={formulationData}/>
                            </TabPanel>
                            <TabPanel sx={{p: 2}} value="2">
                                <FoodInsightsSection food={formulationData}/>
                            </TabPanel>
                        </TabContext>
                    }
                </Box>}
        </>
    );
}
