import {Button, Grid, IconButton, Paper, Tooltip} from "@mui/material";
import {useMemo, useState} from "react";
import {Add, Remove} from "@mui/icons-material";
import {MaterialReactTable} from "material-react-table";
import {IngredientsModal} from "../../../components/ingredients-modal";
import {MaterialEntity} from "../../../api";

export default function NewFoodIngredients(props: { state: any, setState: any }) {

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
                size: 100,
            },
            // {
            //     accessorKey: 'alternative_names',
            //     header: 'Alternative Names',
            //     size: 100,
            //     Cell: ({ renderedCellValue }) => <ChipStack stringArray={renderedCellValue} />,
            // },
            // {
            //     accessorKey: 'macronutrient',
            //     header: 'Macronutrient',
            //     size: 80,
            // },
            // {
            //     accessorKey: 'calories',
            //     header: 'Calories',
            //     size: 80,
            // },
            // {
            //     accessorKey: 'properties.sources',
            //     header: 'Sources',
            //     size: 80,
            //     Cell: ({ renderedCellValue }) => <ChipStack stringArray={renderedCellValue} />,
            // },
            // {
            //     accessorKey: 'properties.labels',
            //     header: 'Labels',
            //     size: 80,
            //     Cell: ({ renderedCellValue }) => <ChipStack stringArray={renderedCellValue} />,
            // },
            // {
            //     accessorKey: 'properties.regulatory',
            //     header: 'Regulatory',
            //     columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
            //     size: 100,
            //     Cell: ({ renderedCellValue }) => <ChipStack stringArray={renderedCellValue} />,
            // },
            // {
            //     accessorKey: 'properties.geography',
            //     header: 'Geography',
            //     columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
            //     size: 100,
            //     Cell: ({ renderedCellValue }) => <ChipStack stringArray={renderedCellValue} />,
            // },
        ],
        [],
    );

    /*function handleAddIngredient(row) {
        // get value from user
        const value = Number(prompt(`How many grams of ${row.original.name}?`))

        // build new ingredient object
        const newIngredient = {
            uid: row.original.uid,
            name: row.original.name,
            value: value
        }

        // build new macronutrients object
        let newMacronutrients

        if (state.macronutrients.length === 0) {
            // create new macronutrients object with the new macronutrient and ingredient objects
            newMacronutrients = [{
                name: row.original.macronutrient,
                value: value,
                ingredients: [newIngredient]
            }]

        } else {
            // check if the macronutrient already exists
            const index = state.macronutrients.findIndex((element) => element.name === row.original.macronutrient)

            if (index === -1) {
                // create new macronutrients object with the new macronutrient and ingredient objects
                newMacronutrients = [
                    ...state.macronutrients,
                    {
                        name: row.original.macronutrient,
                        value: value,
                        ingredients: [newIngredient]
                    }
                ]
            } else {
                // create new macronutrients object with the new ingredient object
                newMacronutrients = state.macronutrients.map((element) => {
                    if (element.name === row.original.macronutrient) {
                        return {
                            ...element,
                            value: element.value + value,
                            ingredients: [...element.ingredients, newIngredient]
                        }
                    }
                    return element
                })
            }
        }

        // update state
        setState(
            {
                ...state,
                ingredients: [...state.ingredients, newIngredient.name],
                macronutrients: newMacronutrients
            }
        )
    }*/

    function handleRemoveIngredient(row: any) {
        // new ingredients list without the removed ingredient
        const newIngredients = props.state.ingredients.filter((element: any) => element.name !== row.original.name)

        // update state
        props.setState(
            {
                ...props.state,
                ingredients: newIngredients,
            }
        )
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function updateIngredients(ingredient: MaterialEntity) {

        props.setState(
            {
                ...props.state,
                ingredients: [...props.state.ingredients, ingredient]
            }
        )
    }

    return (
        <Grid container columnSpacing={3} sx={{pr: 3}}>
            <Grid item xs={10}>
                <Paper>
                    <MaterialReactTable columns={columns}
                                        data={props.state.ingredients}
                                        initialState={{density: 'compact'}}
                                        enableColumnFilterModes
                                        enableRowActions
                                        renderTopToolbarCustomActions={() => {
                                            return (
                                                <div>
                                                    <Button onClick={handleOpen}
                                                            variant={"outlined"}
                                                            startIcon={<Add/>}>
                                                        Add New Ingredient
                                                    </Button>
                                                    <IngredientsModal setIngredient={updateIngredients}
                                                                      open={open} handleClose={handleClose}/>
                                                </div>
                                            )
                                        }}
                                        renderRowActions={({row}) => {
                                            return (<Tooltip title={`Remove ${row.original.name}`} arrow
                                                             placement="top">
                                                    <IconButton onClick={() => handleRemoveIngredient(row)}>
                                                        <Remove/>
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        }}
                        /*renderRowActions={({row}) => {
                            if (state.ingredients.includes(row.original.name)) {
                                return (
                                    <Tooltip title={`Remove ${row.original.name}`} arrow
                                             placement="top">
                                        <IconButton onClick={() => handleRemoveIngredient(row)}>
                                            <Remove/>
                                        </IconButton>
                                    </Tooltip>
                                )
                            } else {
                                return (
                                    <Tooltip title={`Add ${row.original.name}`} arrow placement="top">
                                        <IconButton onClick={() => handleAddIngredient(row)}
                                                    key={row.original.uid}>
                                            <Add color={"primary"}/>
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        }}*/
                    />
                </Paper>
            </Grid>
            {/*<Grid item xs={2}>*/}
            {/*    <Typography variant={'subtitle1'} color={"primary"} paragraph>Ingredients</Typography>*/}
            {/*    {state.macronutrients.length === 0 ?*/}
            {/*        <Typography variant={'subtitle2'} color={"text.secondary"} paragraph>*/}
            {/*            No ingredients added yet*/}
            {/*        </Typography>*/}
            {/*        :*/}
            {/*        <Stack direction={'column'} spacing={1} divider={<Divider flexItem />}>*/}
            {/*            {state.macronutrients.map((macronutrient) =>*/}
            {/*                <>*/}
            {/*                    <Typography variant={'subtitle2'}>{macronutrient.name}</Typography>*/}
            {/*                    <List dense>*/}
            {/*                        {macronutrient.ingredients.map((value) =>*/}
            {/*                            <ListItem>*/}
            {/*                                <ListItemIcon>*/}
            {/*                                    <Extension fontSize={'small'} />*/}
            {/*                                </ListItemIcon>*/}
            {/*                                <ListItemText primary={`${value.value} g ${value.name}`}/>*/}
            {/*                            </ListItem>*/}
            {/*                        )}*/}
            {/*                    </List>*/}
            {/*                </>*/}
            {/*            )}*/}
            {/*        </Stack>*/}
            {/*    }*/}
            {/*</Grid>*/}
        </Grid>
    )
}