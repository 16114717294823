import React from 'react';
import {Box, Button, Typography, Chip} from '@mui/material';

// ----------------------------------------------------------------------

export default function TypeIngredientCardList(
    props: {
        selectedType: string[] | undefined;  // Updated to string[] | undefined for multiple selections
        setSelectedType: React.Dispatch<React.SetStateAction<string[] | undefined>>; // Updated setter to support array
        ingredientTypes: { label: string, value: string }[]; // Array with label and value for displaying
    }
) {
    // Handle selecting and deselecting types
    const handleSelect = (value: string) => {
        let selectedTypesArray = props.selectedType || []; // Fallback to empty array if undefined

        if (selectedTypesArray.includes(value)) {
            // If the type is already selected, remove it
            const updatedArray = selectedTypesArray.filter((selected) => selected !== value);
            props.setSelectedType(updatedArray.length > 0 ? updatedArray : undefined); // Set to undefined if the array is empty
        } else {
            // If the type is not selected, add it
            const updatedArray = [...selectedTypesArray, value];
            props.setSelectedType(updatedArray);
        }
    };

    // Check if any type is selected, fallback to empty array if undefined
    const selectedTypesArray = props.selectedType || [];

    return (
        <Box sx={{paddingTop: '12px', maxWidth: 300, marginBottom: '16px'}}>
            {/* Current selection label */}
            <Button
                variant="outlined"
                color="primary"
                sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    textTransform: 'none',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    ':hover': {
                        backgroundColor: 'primary.light',
                        borderColor: 'primary.main',
                        boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.15)',
                    },
                }}
            >
                <Typography component="span" variant="subtitle2">
                    {selectedTypesArray.length === 0 ? 'None' : selectedTypesArray.join(', ')}
                </Typography>
            </Button>

            {/* List-like ChipStack for ingredient types */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack chips in a vertical list style
                    gap: 1, // Space between chips
                    mt: 2,
                    maxHeight: 300,
                    overflowY: 'auto', // Enable vertical scrolling if needed
                }}
            >
                {props.ingredientTypes.map((type) => (
                    <Chip
                        key={type.value} // Use the unique value as the key
                        label={type.label} // Display the label
                        clickable
                        color={selectedTypesArray.includes(type.value) ? 'primary' : 'default'} // Select based on value
                        onClick={() => handleSelect(type.value)} // Use value for selection logic
                        sx={{
                            fontSize: '14px',
                            cursor: 'pointer',
                            borderRadius: '16px',
                            width: '60%', // Make each chip take 50% width
                            padding: '8px',
                            textAlign: 'center', // Center the text
                            justifyContent: 'center', // Center the content horizontally
                        }}
                    />
                ))}
            </Box>
        </Box>
    );
}
