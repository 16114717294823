import {SetStateAction, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {apiRoutes, ingredientTypes} from "../../config";
import Box from "@mui/material/Box";
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import {StyledSearch} from "../search";
import {Add, Search} from "@mui/icons-material";
import {SortIngredientCardList} from "../../sections/@dashboard/ingredients";
import Pagination from "@mui/material/Pagination";
import openAPIGeneratorMaterialLibraryInstance from "../../openAPIGeneratorMaterialLibraryInstance";
import {MaterialEntity} from "../../api";

export default function IngredientsModal(props: { setIngredient: any, open: any, handleClose: any }) {
    function updateIngredients(ingredient: MaterialEntity) {
        props.setIngredient(ingredient)
        props.handleClose();
    }

    const [queryKey, setQueryKey] = useState('');

    const handleQueryByKey = (event: { target: { value: SetStateAction<string>; }; }) => {
        setPage(1);
        setQueryKey(event.target.value);
    };

    const sortInitialState = {
        property: 'none',
        order: 'desc',
        label: 'None'
    }
    const [sort, setSort] = useState(sortInitialState);

    const [page, setPage] = useState(1)

    const handlePageChange = (event: any, page: SetStateAction<number>) => {
        setPage(page);
    }

    const pageSize = 10

    const sortOptions = [
        {property: 'none', order: "asc", label: 'None'},
        {property: 'date', order: "desc", label: 'Newest'},
        {property: 'date', order: "asc", label: 'Oldest'},
        {property: 'name', order: "desc", label: 'Desc: Name'},
        {property: 'name', order: "asc", label: 'Asc: Name'},
    ];

    const {data: ingredientsData, isSuccess} = useQuery(
        {
            queryKey: [apiRoutes.materialLibrary.baseEndpoint, page, queryKey, sort],
            queryFn: () => {
                return openAPIGeneratorMaterialLibraryInstance
                    .materialLibraryMaterialEntitiesList(undefined, undefined, page, pageSize, queryKey)
                    .then(response => response.data)
            },
            initialData: {
                "count": 0,
                "next": null,
                "previous": null,
                "results": []
            }
        }
    )

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogTitle>Add New Ingredient</DialogTitle>
            <DialogContent>
                <Stack direction={"row"} spacing={2}>
                    <StyledSearch
                        fontSize={16}
                        //disabled
                        fullWidth
                        value={queryKey}
                        onChange={handleQueryByKey}
                        placeholder="Search..."
                        startAdornment={
                            <InputAdornment position="start">
                                <Search/>
                            </InputAdornment>
                        }
                    />
                    {/*<SortIngredientCardList value={sort}*/}
                    {/*                        setValue={setSort}*/}
                    {/*                        sortOptions={sortOptions}/>*/}
                </Stack>
            </DialogContent>
            <DialogContent>
                <Grid container spacing={2}>
                    {isSuccess ?
                        <Container>
                            <List>
                                {ingredientsData.results.map((ingredient: MaterialEntity) => (
                                    <ListItem
                                        key={ingredient.id}
                                        secondaryAction={
                                            <IconButton
                                                onClick={() => updateIngredients(ingredient)}
                                            >
                                                <Add/>
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={ingredient.name}
                                            secondary={ingredientTypes[ingredient.type]}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Container> : <></>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid item xs={12}>
                    <Stack spacing={2} alignItems={"center"}>
                        <Pagination
                            variant="outlined"
                            shape="rounded"
                            count={ingredientsData ? Math.ceil(ingredientsData.count / pageSize) : 0}
                            page={page}
                            onChange={handlePageChange}
                        />
                    </Stack>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}