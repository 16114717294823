import React, {useMemo, useState} from "react";
// @mui
import {Box, Button, Divider, Grid, Paper, Typography} from "@mui/material";
// material react table
import {MaterialReactTable, MRT_ColumnDef} from "material-react-table";
// components
import {FoodInfoCard} from "../../../components/food-info-card";
// mock
import {apiRoutes} from "../../../config";
import {
    MaterialEntity,
    MaterialEntityContainsMaterialEntity,
    PaginatedMaterialEntityContainsMaterialEntityList
} from "../../../api";
import openAPIGeneratorMaterialLibraryInstance from "../../../openAPIGeneratorMaterialLibraryInstance";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Add} from "@mui/icons-material";
import {IngredientsModal} from "../../../components/ingredients-modal";
import MRTButtonStack, {
    IngredientAlternativeButton,
    IngredientDetailButton,
    IngredientRemoveButton
} from "../../../components/ingredient-buttons/IngredientButtons";

// ----------------------------------------------------------------------
export const ingredientContentsPageQuery = (
    materialEntity?: number,
    page?: number,
    pageSize?: number,
    search?: string,
) => ({
    queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint, materialEntity, "contents", search, page, pageSize],
    queryFn: () => {
        return openAPIGeneratorMaterialLibraryInstance
            .materialLibraryMaterialEntityContainsMaterialEntityList(materialEntity, page, pageSize, search)
            .then(response => response.data);
    },
});


export function IngredientsTable(props: {
    food: MaterialEntity
    detail: boolean
    edit: boolean
    alternative: boolean
}) {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20, //customize the default page size
    });
    const queryClient = useQueryClient()
    const [globalFilter, setGlobalFilter] = useState('');

    const {
        data: ingredientsData,
        isLoading: ingredientsDataIsLoading,
        isSuccess: ingredientsDataIsSuccess
    } = useQuery<PaginatedMaterialEntityContainsMaterialEntityList>(
        ingredientContentsPageQuery(props.food.id, pagination.pageIndex + 1, pagination.pageSize, globalFilter)
    );


    const columns: MRT_ColumnDef<MaterialEntityContainsMaterialEntity>[] = useMemo(
        () => {
            return [
                {
                    accessorKey: 'child_material_entity.name',
                    header: 'Name',
                    columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                    size: 150,
                    Cell: ({renderedCellValue}: any) => <Typography
                        variant={"subtitle2"}>{renderedCellValue}</Typography>,
                },
            ]
        },
        [],
    );

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const foodContentMutation: any = useMutation({
        mutationFn: (material_entity: MaterialEntity) => {
            return openAPIGeneratorMaterialLibraryInstance.materialLibraryMaterialEntityContainsMaterialEntityCreate(
                {
                    material_entity: props.food.id,
                    child_material_entity: material_entity.id
                }
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [apiRoutes.materialLibrary.materialEntity.baseEndpoint]})
        },
    })


    return <Box>
        <Paper>
            <MaterialReactTable
                columns={columns}
                data={ingredientsData ? ingredientsData.results : []}
                enableColumnFilterModes
                enableRowActions
                renderRowActions={({row}) => (
                    <MRTButtonStack>
                        {props.detail ? <IngredientDetailButton
                            foodName={props.food.name}
                            ingredientUID={row.original.child_material_entity.id}
                            ingredientName={row.original.child_material_entity.name}
                            ingredientType={row.original.child_material_entity.type}
                            contentUID={row.original.id}
                        /> : null}
                        {props.alternative ? <IngredientAlternativeButton
                            foodName={props.food.name}
                            ingredientUID={row.original.child_material_entity.id}
                            ingredientName={row.original.child_material_entity.name}
                            ingredientType={row.original.child_material_entity.type}
                            contentUID={row.original.id}
                        /> : null}
                        {props.edit ? <IngredientRemoveButton
                            foodName={props.food.name}
                            ingredientUID={row.original.child_material_entity.id}
                            ingredientName={row.original.child_material_entity.name}
                            ingredientType={row.original.child_material_entity.type}
                            contentUID={row.original.id}
                        /> : null}
                    </MRTButtonStack>
                )}
                renderTopToolbarCustomActions={() => {
                    return props.edit ? (
                        <div>
                            <Button onClick={handleOpen}
                                    variant={"outlined"}
                                    startIcon={<Add/>}>
                                Add New Ingredient
                            </Button>
                            <IngredientsModal setIngredient={foodContentMutation.mutate} open={open}
                                              handleClose={handleClose}/>
                        </div>
                    ) : null
                }}
                enableDensityToggle={false}
                enableColumnFilters={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableHiding={false}

                // Sever Side mode

                enablePagination={true}
                manualPagination={true}
                manualFiltering={true} //turn off client-side filtering
                onGlobalFilterChange={setGlobalFilter} //hoist internal global state to your state
                onPaginationChange={setPagination}
                state={{
                    pagination: pagination,
                    globalFilter: globalFilter
                }} //pass in your own managed globalFilter state
                rowCount={ingredientsData?.count ?? 0}
            />
        </Paper>
    </Box>
}

export default function FoodDetails(props: { food: any }) {

    return (
        <Grid container
              columnSpacing={2}
              rowSpacing={4}
              justifyContent="flex-start"
              alignItems="flex-start">
            <Grid item xs={12}>
                <FoodInfoCard food={props.food}/>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <Divider sx={{mb: 2}}/>
                    <Typography variant={"h5"} color={"primary"} paragraph>
                        Formulation
                    </Typography>
                    <IngredientsTable food={props.food} detail={true} alternative={true} edit={true}/>
                </Box>
            </Grid>
        </Grid>
    )
}