import React, {useState, useEffect, Dispatch} from 'react';
// @mui
import {Box, Chip, Button, Typography} from '@mui/material';

// ---------------------------------------------------------------------------------------------------------------------

export function ClearFilterIngredientCardList(props: {
    value: string[] | undefined;
    setValue: Dispatch<React.SetStateAction<string[] | undefined>>;
}) {

    // Clear all filters by setting an empty array.
    const handleClearFilters = () => {
        props.setValue([]); // Reset the selection.
    };

    // Determine if there are any chips selected.
    const selectedChips = props.value && props.value.length > 0;
    return (
        <Box sx={{paddingTop: '12px'}}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center', // Center the button
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!selectedChips} // Disable if no chips are selected
                    onClick={handleClearFilters} // Call handleClearFilters on button click
                    sx={{
                        borderRadius: '20px',
                        padding: '10px 24px',
                        textTransform: 'none',
                        boxShadow: selectedChips
                            ? '0px 4px 12px rgba(0, 0, 0, 0.2)' // Add a shadow if enabled
                            : 'none',
                        transition: 'all 0.3s ease',
                        ':hover': {
                            backgroundColor: selectedChips ? 'primary.dark' : 'primary.main',
                        },
                    }}
                >
                    Clear Filters
                </Button>
            </Box>
        </Box>
    );
}


export default function FilterIngredientCardList(props: {
    value: string[] | undefined;
    setValue: Dispatch<React.SetStateAction<string[] | undefined>>;
    allFilters: string[] | undefined;
}) {
    const {value, setValue} = props

    // Ensure the value is initialized as an empty array if undefined or empty.
    useEffect(() => {
        if (!value || value.length === 0) {
            setValue([]); // Start with an empty selection
        }
    }, [value, setValue]);

    // Handle selecting/deselecting a filter when the user clicks on a chip.
    const handleSelect = (filter: string) => {
        const currentSelections = props.value || [];

        // Toggle the filter in the value array.
        if (currentSelections.includes(filter)) {
            // Remove the filter if it was already selected.
            const newSelections = currentSelections.filter(item => item !== filter);
            props.setValue(newSelections);// Update the parent component's state.
        } else {
            // Add the filter if it was not selected.
            const newSelections = [...currentSelections, filter];
            props.setValue(newSelections); // Update the parent component's state.
        }
    };


    return (
        <Box sx={{paddingTop: '12px'}}>
            {/* Chip Stack */}
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap', // Chips will wrap into a new row if the space is filled
                    gap: '8px', // Space between chips
                    marginBottom: '16px', // Add space between the chips and the button
                }}
            >

                {props.allFilters?.map((filter: string, index: number) => (
                    <Chip
                        key={filter}
                        label={filter}
                        clickable
                        color={props.value?.includes(filter) ? 'primary' : 'default'}
                        onClick={() => handleSelect(filter)} // Call handleSelect when chip is clicked
                        size="small" // Smaller chips
                        sx={{
                            cursor: 'pointer',
                            marginBottom: '8px', // Adds spacing between rows
                        }}
                    />
                ))}

            </Box>

        </Box>
    );
}

